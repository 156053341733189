import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { getFlatPossibleValues, getValueId } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Checkbox } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { NavigatorValue } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorValue'
import { PossibleNavigatorValue, SelectedNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { getParentIdFromValue } from '@bbx/search-journey/sub-domains/search/lib/geo-navigators'

export const ProvinceSelectedValues: FunctionComponent<NavigatorProps> = ({
    navigator,
    onSearch,
    'aria-labelledby': ariaLabelledBy,
    taggingData,
}) => {
    const navigatorLabelId = `navigator-title-${navigator.id}`
    const combinedAriaLabelledBy = [ariaLabelledBy, navigatorLabelId].filter(Boolean).join(' ')

    return (
        <Box
            display="flex"
            flexDirection="column"
            paddingHorizontal={{ phone: 'm', tablet: 's' }}
            paddingBottom={{ phone: 'm', tablet: 0 }}
            backgroundColor={{ phone: 'palette.polarbear', tablet: 'transparent' }}
            aria-labelledby={ariaLabelledBy}
            testId={`${navigator.id}-selected-values`}
        >
            {navigator.selectedValues.map((selectedValue) => (
                <Checkbox
                    key={getValueId(selectedValue)}
                    id={`navigator-selected-value-${navigator.id}-${selectedValue.label}-checkbox`}
                    testId={`navigator-selected-value-${navigator.id}-${selectedValue.label}-checkbox`}
                    checked={true}
                    onChange={() => {
                        callActionEvent('search_result_list_reset_click_navigators', taggingData)
                        onSearch(selectedValue.resetLink!)
                    }}
                    marginTop={{ phone: 'm', tablet: 's' }}
                    paddingVertical={0}
                    paddingLeft={getParentIdFromValue(selectedValue) ? 'm' : undefined}
                    label={
                        <Box paddingLeft={2} width="100%">
                            <NavigatorValue
                                value={{
                                    ...selectedValue,
                                    hits: getHitsByValueId(getFlatPossibleValues(navigator), selectedValue),
                                }}
                                id={navigator.id}
                                aria-labelledby={combinedAriaLabelledBy}
                                href={selectedValue.resetLink?.relativePath}
                                showHitsLabel={true}
                            />
                        </Box>
                    }
                    // we use an empty parent context because the Checkbox uses its label as aria-labelledby, and the PossibleValue already contains the parent context
                />
            ))}
        </Box>
    )
}

const getHitsByValueId = (possibleValues: PossibleNavigatorValue[], selectedValue: SelectedNavigatorValue) => {
    return possibleValues.filter((value) => getValueId(value) === getValueId(selectedValue))?.[0]?.hits
}
