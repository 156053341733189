import React, { FunctionComponent } from 'react'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { Select } from '@wh-components/core/FormElements/Select/Select'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { LayoutProps } from '@wh-components/system/layout'
import { SpaceProps } from '@wh-components/system/space'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { SearchId } from '@bbx/common/api/dtos/search-id'
import { getValueId } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

export const SearchIdNavigator: FunctionComponent<
    NavigatorProps &
        LayoutProps &
        SpaceProps & { getTaggingAction?: (searchId: SearchId) => TaggingActionEvent | undefined; ariaLabel?: string }
> = ({
    navigator,
    taggingData,
    onSearch,
    getTaggingAction = () => 'search_result_list_filter_object_types_click',
    ariaLabel,
    ...props
}) => {
    const possibleValues = navigator.groupedPossibleValues[0].possibleValues.map((possibleValue) => ({
        label: possibleValue.label,
        value: possibleValue.urlParamRepresentationForValue[0].value,
    }))
    const selectedValue = navigator.selectedValues[0].urlParamRepresentationForValue[0].value

    return (
        <Select
            id="searchid-select"
            testId="searchid-select"
            aria-label={ariaLabel}
            size={{ phone: 'large', tablet: 'medium' }}
            marginTop="s"
            marginBottom="s"
            items={possibleValues}
            selectionEmphasis={true}
            value={selectedValue}
            onChange={async (event) => {
                const selectedPossibleValue = navigator.groupedPossibleValues[0].possibleValues.find(
                    (possibleValue) => possibleValue.urlParamRepresentationForValue[0].value === event.target.value,
                )
                if (selectedPossibleValue) {
                    if (selectedPossibleValue.webLink?.relativePath) {
                        await BbxRouter.push({
                            href: selectedPossibleValue.webLink.relativePath,
                            clientSideRouting: false, // server-side routing because "useBackbutton" doesn't work correctly between the result-lists of different verticals
                        })
                    } else {
                        await onSearch(selectedPossibleValue.searchLink)
                    }
                }
                if (getTaggingAction && selectedPossibleValue) {
                    const taggingAction = getTaggingAction(getValueId(selectedPossibleValue) as unknown as SearchId)
                    taggingAction && callActionEvent(taggingAction, taggingData)
                }
            }}
            {...props}
        />
    )
}
