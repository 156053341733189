/* eslint-disable max-lines */
import { verticals } from '@wh/common/chapter/types/verticals'

interface Area {
    areaId: number
    name: string
}

interface State extends Area {
    districtList: Array<Area>
}

const austrianStateList: Array<State> = [
    {
        areaId: 1,
        name: 'Burgenland',
        districtList: [
            {
                areaId: 101,
                name: 'Eisenstadt',
            },
            {
                areaId: 103,
                name: 'Eisenstadt - Umgebung',
            },
            {
                areaId: 104,
                name: 'Güssing',
            },
            {
                areaId: 105,
                name: 'Jennersdorf',
            },
            {
                areaId: 106,
                name: 'Mattersburg',
            },
            {
                areaId: 107,
                name: 'Neusiedl am See',
            },
            {
                areaId: 108,
                name: 'Oberpullendorf',
            },
            {
                areaId: 109,
                name: 'Oberwart',
            },
            {
                areaId: 102,
                name: 'Rust (Stadt)',
            },
        ],
    },
    {
        areaId: 2,
        name: 'Kärnten',
        districtList: [
            {
                areaId: 210,
                name: 'Feldkirchen',
            },
            {
                areaId: 203,
                name: 'Hermagor',
            },
            {
                areaId: 201,
                name: 'Klagenfurt',
            },
            {
                areaId: 204,
                name: 'Klagenfurt Land',
            },
            {
                areaId: 205,
                name: 'Sankt Veit an der Glan',
            },
            {
                areaId: 206,
                name: 'Spittal an der Drau',
            },
            {
                areaId: 202,
                name: 'Villach',
            },
            {
                areaId: 207,
                name: 'Villach Land',
            },
            {
                areaId: 208,
                name: 'Völkermarkt',
            },
            {
                areaId: 209,
                name: 'Wolfsberg',
            },
        ],
    },
    {
        areaId: 3,
        name: 'Niederösterreich',
        districtList: [
            {
                areaId: 305,
                name: 'Amstetten',
            },
            {
                areaId: 306,
                name: 'Baden',
            },
            {
                areaId: 307,
                name: 'Bruck an der Leitha',
            },
            {
                areaId: 308,
                name: 'Gänserndorf',
            },
            {
                areaId: 309,
                name: 'Gmünd',
            },
            {
                areaId: 310,
                name: 'Hollabrunn',
            },
            {
                areaId: 311,
                name: 'Horn',
            },
            {
                areaId: 312,
                name: 'Korneuburg',
            },
            {
                areaId: 301,
                name: 'Krems an der Donau',
            },
            {
                areaId: 313,
                name: 'Krems Land',
            },
            {
                areaId: 314,
                name: 'Lilienfeld',
            },
            {
                areaId: 315,
                name: 'Melk',
            },
            {
                areaId: 316,
                name: 'Mistelbach',
            },
            {
                areaId: 317,
                name: 'Mödling',
            },
            {
                areaId: 318,
                name: 'Neunkirchen',
            },
            {
                areaId: 302,
                name: 'Sankt Pölten',
            },
            {
                areaId: 319,
                name: 'Sankt Pölten Land',
            },
            {
                areaId: 320,
                name: 'Scheibbs',
            },
            {
                areaId: 321,
                name: 'Tulln',
            },
            {
                areaId: 322,
                name: 'Waidhofen an der Thaya',
            },
            {
                areaId: 303,
                name: 'Waidhofen an der Ybbs',
            },
            {
                areaId: 304,
                name: 'Wiener Neustadt',
            },
            {
                areaId: 323,
                name: 'Wiener Neustadt Land',
            },
            {
                areaId: 325,
                name: 'Zwettl',
            },
        ],
    },
    {
        areaId: 4,
        name: 'Oberösterreich',
        districtList: [
            {
                areaId: 404,
                name: 'Braunau am Inn',
            },
            {
                areaId: 405,
                name: 'Eferding',
            },
            {
                areaId: 406,
                name: 'Freistadt',
            },
            {
                areaId: 407,
                name: 'Gmunden',
            },
            {
                areaId: 408,
                name: 'Grieskirchen',
            },
            {
                areaId: 409,
                name: 'Kirchdorf an der Krems',
            },
            {
                areaId: 401,
                name: 'Linz',
            },
            {
                areaId: 410,
                name: 'Linz Land',
            },
            {
                areaId: 411,
                name: 'Perg',
            },
            {
                areaId: 412,
                name: 'Ried im Innkreis',
            },
            {
                areaId: 413,
                name: 'Rohrbach',
            },
            {
                areaId: 414,
                name: 'Schärding',
            },
            {
                areaId: 402,
                name: 'Steyr',
            },
            {
                areaId: 415,
                name: 'Steyr-Land',
            },
            {
                areaId: 416,
                name: 'Urfahr-Umgebung',
            },
            {
                areaId: 417,
                name: 'Vöcklabruck',
            },
            {
                areaId: 403,
                name: 'Wels',
            },
            {
                areaId: 418,
                name: 'Wels-Land',
            },
        ],
    },
    {
        areaId: 5,
        name: 'Salzburg',
        districtList: [
            {
                areaId: 502,
                name: 'Hallein',
            },
            {
                areaId: 501,
                name: 'Salzburg Stadt',
            },
            {
                areaId: 503,
                name: 'Salzburg-Umgebung',
            },
            {
                areaId: 504,
                name: 'Sankt Johann im Pongau',
            },
            {
                areaId: 505,
                name: 'Tamsweg',
            },
            {
                areaId: 506,
                name: 'Zell am See',
            },
        ],
    },
    {
        areaId: 6,
        name: 'Steiermark',
        districtList: [
            {
                areaId: 621,
                name: 'Bruck-Mürzzuschlag',
            },
            {
                areaId: 603,
                name: 'Deutschlandsberg',
            },
            {
                areaId: 601,
                name: 'Graz',
            },
            {
                areaId: 606,
                name: 'Graz-Umgebung',
            },
            {
                areaId: 622,
                name: 'Hartberg-Fürstenfeld',
            },
            {
                areaId: 610,
                name: 'Leibnitz',
            },
            {
                areaId: 611,
                name: 'Leoben',
            },
            {
                areaId: 612,
                name: 'Liezen',
            },
            {
                areaId: 614,
                name: 'Murau',
            },
            {
                areaId: 620,
                name: 'Murtal',
            },
            {
                areaId: 623,
                name: 'Südoststeiermark',
            },
            {
                areaId: 616,
                name: 'Voitsberg',
            },
            {
                areaId: 617,
                name: 'Weiz',
            },
        ],
    },
    {
        areaId: 7,
        name: 'Tirol',
        districtList: [
            {
                areaId: 702,
                name: 'Imst',
            },
            {
                areaId: 701,
                name: 'Innsbruck',
            },
            {
                areaId: 703,
                name: 'Innsbruck-Land',
            },
            {
                areaId: 704,
                name: 'Kitzbühel',
            },
            {
                areaId: 705,
                name: 'Kufstein',
            },
            {
                areaId: 706,
                name: 'Landeck',
            },
            {
                areaId: 707,
                name: 'Lienz',
            },
            {
                areaId: 708,
                name: 'Reutte',
            },
            {
                areaId: 709,
                name: 'Schwaz',
            },
        ],
    },
    {
        areaId: 8,
        name: 'Vorarlberg',
        districtList: [
            {
                areaId: 801,
                name: 'Bludenz',
            },
            {
                areaId: 802,
                name: 'Bregenz',
            },
            {
                areaId: 803,
                name: 'Dornbirn',
            },
            {
                areaId: 804,
                name: 'Feldkirch',
            },
        ],
    },
    {
        areaId: 900,
        name: 'Wien',
        districtList: [
            {
                areaId: 117223,
                name: 'Wien, 01. Bezirk, Innere Stadt',
            },
            {
                areaId: 117224,
                name: 'Wien, 02. Bezirk, Leopoldstadt',
            },
            {
                areaId: 117225,
                name: 'Wien, 03. Bezirk, Landstraße',
            },
            {
                areaId: 117226,
                name: 'Wien, 04. Bezirk, Wieden',
            },
            {
                areaId: 117227,
                name: 'Wien, 05. Bezirk, Margareten',
            },
            {
                areaId: 117228,
                name: 'Wien, 06. Bezirk, Mariahilf',
            },
            {
                areaId: 117229,
                name: 'Wien, 07. Bezirk, Neubau',
            },
            {
                areaId: 117230,
                name: 'Wien, 08. Bezirk, Josefstadt',
            },
            {
                areaId: 117231,
                name: 'Wien, 09. Bezirk, Alsergrund',
            },
            {
                areaId: 117232,
                name: 'Wien, 10. Bezirk, Favoriten',
            },
            {
                areaId: 117233,
                name: 'Wien, 11. Bezirk, Simmering',
            },
            {
                areaId: 117234,
                name: 'Wien, 12. Bezirk, Meidling',
            },
            {
                areaId: 117235,
                name: 'Wien, 13. Bezirk, Hietzing',
            },
            {
                areaId: 117236,
                name: 'Wien, 14. Bezirk, Penzing',
            },
            {
                areaId: 117237,
                name: 'Wien, 15. Bezirk, Rudolfsheim-Fünfhaus',
            },
            {
                areaId: 117238,
                name: 'Wien, 16. Bezirk, Ottakring',
            },
            {
                areaId: 117239,
                name: 'Wien, 17. Bezirk, Hernals',
            },
            {
                areaId: 117240,
                name: 'Wien, 18. Bezirk, Währing',
            },
            {
                areaId: 117241,
                name: 'Wien, 19. Bezirk, Döbling',
            },
            {
                areaId: 117242,
                name: 'Wien, 20. Bezirk, Brigittenau',
            },
            {
                areaId: 117243,
                name: 'Wien, 21. Bezirk, Floridsdorf',
            },
            {
                areaId: 117244,
                name: 'Wien, 22. Bezirk, Donaustadt',
            },
            {
                areaId: 117245,
                name: 'Wien, 23. Bezirk, Liesing',
            },
        ],
    },
]

const otherCountriesListDefault = [
    {
        areaId: 22000,
        name: 'andere Länder',
        districtList: [
            {
                areaId: -122,
                name: 'Bosnien und Herzegowina',
            },
            {
                areaId: -119,
                name: 'Bulgarien',
            },
            {
                areaId: -137,
                name: 'Deutschland',
            },
            {
                areaId: -166,
                name: 'Frankreich',
            },
            {
                areaId: -22,
                name: 'Griechenland',
            },
            {
                areaId: -21,
                name: 'Großbritannien',
            },
            {
                areaId: -127,
                name: 'Italien',
            },
            {
                areaId: -147,
                name: 'Kanada',
            },
            {
                areaId: -128,
                name: 'Kroatien',
            },
            {
                areaId: -130,
                name: 'Malta',
            },
            {
                areaId: -173,
                name: 'Montenegro',
            },
            {
                areaId: -57,
                name: 'Niederlande',
            },
            {
                areaId: -44,
                name: 'Polen',
            },
            {
                areaId: -132,
                name: 'Portugal',
            },
            {
                areaId: -133,
                name: 'Russland',
            },
            {
                areaId: -13,
                name: 'Schweiz',
            },
            {
                areaId: 20784,
                name: 'Serbien',
            },
            {
                areaId: -168,
                name: 'Slowakei',
            },
            {
                areaId: -169,
                name: 'Slowenien',
            },
            {
                areaId: -134,
                name: 'Spanien',
            },
            {
                areaId: -136,
                name: 'Tschechien',
            },
            {
                areaId: -53,
                name: 'Türkei',
            },
            {
                areaId: -139,
                name: 'Ungarn',
            },
            {
                areaId: -158,
                name: 'USA',
            },
            {
                areaId: 25184,
                name: 'Vereinigte Arabische Emirate',
            },
            {
                areaId: -14,
                name: 'Zypern',
            },
        ],
    },
]

const otherCountriesListMotor = [
    {
        areaId: 22000,
        name: 'andere Länder',
        districtList: [
            {
                areaId: -137,
                name: 'Deutschland',
            },
            {
                areaId: -166,
                name: 'Frankreich',
            },
            {
                areaId: -127,
                name: 'Italien',
            },
            {
                areaId: -128,
                name: 'Kroatien',
            },
            {
                areaId: -57,
                name: 'Niederlande',
            },
            {
                areaId: -44,
                name: 'Polen',
            },
            {
                areaId: -132,
                name: 'Portugal',
            },
            {
                areaId: -13,
                name: 'Schweiz',
            },
            {
                areaId: 20784,
                name: 'Serbien',
            },
            {
                areaId: -168,
                name: 'Slowakei',
            },
            {
                areaId: -169,
                name: 'Slowenien',
            },
            {
                areaId: -134,
                name: 'Spanien',
            },
            {
                areaId: -136,
                name: 'Tschechien',
            },
            {
                areaId: -139,
                name: 'Ungarn',
            },
        ],
    },
]

const stateLists: Record<verticals, Array<State>> = {
    [verticals.BAP]: [...austrianStateList, ...otherCountriesListDefault],
    [verticals.ESTATE]: [...austrianStateList, ...otherCountriesListDefault],
    [verticals.MOTOR]: [...austrianStateList, ...otherCountriesListMotor],
    [verticals.JOB]: [],
}

export const getStateList = (vertical: verticals): Array<State> => stateLists[vertical]

export const defaultStateList = getStateList(verticals.ESTATE)
