import { PossibleNavigatorValue, SelectedNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { defaultStateList } from '../components/common/common/Navigators/ProvinceNavigator/stateList'

export const mergeValues = <T extends SelectedNavigatorValue | PossibleNavigatorValue>(regionNavigator: T[], districtNavigator?: T[]) => {
    return regionNavigator.reduce((acc: T[], region: T) => {
        acc.push(region)
        const districsOfRegion = districtNavigator?.filter((district) => {
            return getParentIdFromValue(district) === region.urlParamRepresentationForValue[0].value
        })
        districsOfRegion && acc.push(...districsOfRegion)

        return acc
    }, [])
}

export const getParentIdFromValue = (district: SelectedNavigatorValue | PossibleNavigatorValue) =>
    district.parentId ?? district.parent?.urlParamRepresentationForValue[0].value

export const removeStatesAlreadyIncludedInDistricts = (selectedAreaIds: string[]) => {
    const alreadyIncludedStateIds = selectedAreaIds.map((areaId) => getParentId(areaId)).filter((areaId) => !!areaId) as number[]

    return selectedAreaIds.filter((areaId) => !alreadyIncludedStateIds.includes(Number(areaId)))
}

const getParentId = (areaId: string) => {
    return defaultStateList.find((state) => state.districtList.some((district) => district.areaId === Number(areaId)))?.areaId
}
