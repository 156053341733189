import React, { FunctionComponent, useMemo } from 'react'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { Box } from '@wh-components/core/Box/Box'
import { WidthProps } from '@wh-components/system/layout'
import { Navigator, NavigatorId, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { NavigatorModalProps } from '../MultiSelectNavigator/ModalNavigatorModal'
import { ModalNavigatorButton } from '../MultiSelectNavigator/ModalNavigator'
import { useProvinceNavigator } from './useProvinceNavigator'
import { ProvinceSelectedValues } from './ProvinceSelectedValues'
import { ProvinceNavigatorModal } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ProvinceNavigator/ProvinceNavigatorModal'
import { NavigatorLabel } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorLabel'
import { mergeValues } from '@bbx/search-journey/sub-domains/search/lib/geo-navigators'
import { ResetAllButton } from '../ResetAllButton/ResetAllButton'
import { verticals } from '@wh/common/chapter/types/verticals'
import { getFlatPossibleValues } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

export interface ProvinceNavigatorProps {
    districtNavigator: Navigator | RangeNavigator | undefined
    id?: NavigatorId
    label?: string
    showResetLink?: boolean
    showSelectedValues?: boolean
    disabled?: boolean
    testIdFragmentOverride?: string
    taggingEvent?: TaggingActionEvent
    vertical: verticals
}

export const ProvinceNavigator: FunctionComponent<NavigatorProps & NavigatorModalProps & ProvinceNavigatorProps & WidthProps> = ({
    id,
    navigator,
    districtNavigator,
    onSearch,
    'aria-labelledby': ariaLabelledBy,
    modalHeader,
    label,
    width = '100%',
    minWidth,
    maxWidth,
    disabled = false,
    showResetLink = true,
    showSelectedValues = true,
    testIdFragmentOverride,
    taggingEvent,
    taggingData,
    vertical,
}) => {
    const navigatorId = navigator?.id ?? id
    const navigatorLabelId = `navigator-title-${navigatorId}`
    const combinedAriaLabelledBy = [ariaLabelledBy, navigatorLabelId].filter(Boolean).join(' ')

    const copiedNavigator = useMemo(
        () => ({
            ...navigator,
            selectedValues: mergeValues(navigator.selectedValues, districtNavigator?.selectedValues),
            groupedPossibleValues: [
                {
                    possibleValues: mergeValues(
                        getFlatPossibleValues(navigator),
                        districtNavigator?.groupedPossibleValues[0]?.possibleValues,
                    ),
                },
            ],
        }),
        [navigator, districtNavigator?.selectedValues, districtNavigator?.groupedPossibleValues],
    )

    const { checkboxState, handleChange, modalOpen, openModal, closeModal, setModalOpen, onSubmitMultiSelect, setCheckboxState } =
        useProvinceNavigator(copiedNavigator, onSearch)

    const hasSelectedValues = copiedNavigator?.selectedValues.length > 0
    const buttonText = !showSelectedValues && hasSelectedValues ? getSelectedValuesText(copiedNavigator) : 'Bundesland/Bezirk'

    return (
        <Box width={width} minWidth={minWidth} maxWidth={maxWidth}>
            {copiedNavigator && (
                <ProvinceNavigatorModal
                    checkboxState={checkboxState}
                    handleChange={handleChange}
                    onSubmitMultiSelect={onSubmitMultiSelect}
                    modalOpen={modalOpen}
                    openModal={openModal}
                    closeModal={closeModal}
                    setModalOpen={setModalOpen}
                    modalHeader={modalHeader}
                    navigator={copiedNavigator}
                    setCheckboxState={setCheckboxState}
                    vertical={vertical}
                />
            )}
            {(label || showResetLink) && (
                <Box display="flex" justifyContent="space-between" marginBottom="xs">
                    {label && <NavigatorLabel testId={`navigator-${testIdFragmentOverride || navigatorId}-label`}>{label}</NavigatorLabel>}
                    {showResetLink && navigator.selectedValues.length >= 1 && (
                        <ResetAllButton
                            navigatorId={navigator.id}
                            onSearch={onSearch}
                            contextLink={navigator.resetAllInformation?.resetAllUrl}
                        />
                    )}
                </Box>
            )}
            <ModalNavigatorButton
                testIdFragment={testIdFragmentOverride || navigatorId}
                width="100%"
                openModal={openModal}
                hasSelectedValues={hasSelectedValues}
                disabled={disabled}
                buttonText={buttonText}
                taggingEvent={taggingEvent}
                taggingData={taggingData}
            />
            {showSelectedValues && hasSelectedValues && (
                <ProvinceSelectedValues
                    navigator={copiedNavigator}
                    onSearch={onSearch}
                    aria-labelledby={combinedAriaLabelledBy}
                    taggingData={taggingData}
                />
            )}
        </Box>
    )
}

const getSelectedValuesText = (navigator: Navigator | RangeNavigator) => {
    return navigator?.selectedValues.map((value) => value.label).join(', ')
}
