import { Navigator } from '@bbx/search-journey/common/Navigators'
import { useMultiSelectCheckboxState } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/useMultiSelectCheckboxState'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { removeStatesAlreadyIncludedInDistricts } from '@bbx/search-journey/sub-domains/search/lib/geo-navigators'
import { CheckboxStateType } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import React, { useState } from 'react'

export const useProvinceNavigator = (navigator: Navigator | undefined, onSearch: SearchCallback) => {
    const { checkboxState, setCheckboxState, handleChange } = useMultiSelectCheckboxState(navigator)

    const [checkBoxStateBeforeModalOpen, setCheckBoxStateBeforeModalOpen] = useState<Record<string, boolean>>(checkboxState)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const onSubmitMultiSelect = async (
        e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
        options?: { keepModalOpen?: boolean; stateOverride?: CheckboxStateType },
    ) => {
        if (!navigator) {
            return
        }
        e?.preventDefault()
        const selectedAreaIds = Object.keys(options?.stateOverride ?? checkboxState)
        const filteredSelectedAreaIds = removeStatesAlreadyIncludedInDistricts(selectedAreaIds)

        const additionalParams = { areaId: filteredSelectedAreaIds }

        if (options?.stateOverride) {
            setCheckboxState(options.stateOverride)
            setCheckBoxStateBeforeModalOpen(options.stateOverride)
        }

        if (navigator.resetAllInformation) {
            await onSearch(navigator.resetAllInformation.resetAllUrl, additionalParams)
        }

        if (!options?.keepModalOpen) {
            setModalOpen(false)
        }
    }

    const openModal = () => {
        setCheckBoxStateBeforeModalOpen(checkboxState)
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)
        setCheckboxState(checkBoxStateBeforeModalOpen)
    }
    return {
        checkboxState,
        handleChange,
        onSubmitMultiSelect,
        modalOpen,
        openModal,
        closeModal,
        setModalOpen,
        setCheckboxState,
    }
}
