import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { ProvinceNavigatorModalContent } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ProvinceNavigator/ProvinceNavigatorModalContent'
import type { useProvinceNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ProvinceNavigator/useProvinceNavigator'
import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import { Modal } from '@wh-components/core/Modal/Modal'
import { verticals } from '@wh/common/chapter/types/verticals'
import React, { FunctionComponent } from 'react'

interface ProvinceNavigatorModalProps {
    label?: string
    modalHeader?: string
    vertical: verticals
}

export const ProvinceNavigatorModal: FunctionComponent<
    Pick<NavigatorProps, 'navigator'> & ProvinceNavigatorModalProps & ReturnType<typeof useProvinceNavigator>
> = ({ checkboxState, handleChange, onSubmitMultiSelect, modalOpen, closeModal, modalHeader, navigator, setCheckboxState, vertical }) => {
    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            fullScreen={{ phone: true, tablet: false }}
            width={{ phone: 'auto', tablet: '720px' }}
            height="100%"
            header={modalHeader ?? 'Region auswählen'}
            testId={`${navigator.id}-modal`}
            footer={
                <Box display="flex" justifyContent="space-between" paddingTop="m" marginTop={-16} borderTop="owl">
                    <Button
                        flex={{ phone: '1 1 50%', tablet: 'inherit' }}
                        minWidth={{ tablet: 150 }}
                        marginRight="m"
                        color="complimentary"
                        testId={`${navigator.id}-dismiss-modal`}
                        onClick={closeModal}
                        size={{ phone: 'large', tablet: 'medium' }}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        flex={{ phone: '1 1 50%', tablet: 'inherit' }}
                        minWidth={{ tablet: 150 }}
                        testId={`${navigator.id}-submit-modal`}
                        onClick={onSubmitMultiSelect}
                        size={{ phone: 'large', tablet: 'medium' }}
                    >
                        Fertig
                    </Button>
                </Box>
            }
        >
            <ProvinceNavigatorModalContent
                navigator={navigator}
                handleChange={handleChange}
                checkboxState={checkboxState}
                setCheckboxState={setCheckboxState}
                onSubmitMultiSelect={onSubmitMultiSelect}
                vertical={vertical}
            />
        </Modal>
    )
}
